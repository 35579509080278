import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from './pipes/pipes.module';
import { ComponentsModule } from './components/components.module';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ModulesModule } from './modules/modules.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SelfieCameraModule } from './modules/selfie-camera/selfie-camera.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CustomFormControlsModule } from './modules/custom-form-controls/custom-form-controls.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_RADIO_DEFAULT_OPTIONS,
  MatRadioModule
} from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DirectivesModule } from './directives/directives.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProfileDetailsModule } from './modules/profile-details/profile-details.module';

@NgModule({
  imports: [CommonModule, MatFormFieldModule],
  exports: [
    PipesModule,
    CommonModule,
    ComponentsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTabsModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatCardModule,
    FlexLayoutModule,
    MatRippleModule,
    ReactiveFormsModule,
    ProfileDetailsModule,
    SelfieCameraModule,
    ModulesModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    DirectivesModule,
    InfiniteScrollModule,
    MatCheckboxModule,
    MatSidenavModule,
    CustomFormControlsModule
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' }
    }
  ]
})
export class SharedModule {}
