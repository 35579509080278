import { INavItem } from '../../shared/types/utils';
import { UserPermission } from '../../shared/types/users';

export const USER_NAV_ROLES: { [key in UserPermission]?: INavItem[] } = {
  [UserPermission.guard]: [
    { label: 'Zlecenia', route: '/orders' },
    { label: 'Profil', route: '/profile' }
  ],
  [UserPermission.manager]: [
    { label: 'Umowy', route: '/contracts' },
    { label: 'Zlecenia', route: '/orders' }
  ],
  [UserPermission.shop_owner]: [
    { label: 'Zlecenia', route: '/orders' },
    { label: 'Zaproś użytkownika', route: '/invitation' }
  ],
  [UserPermission.zabka_manager]: [
    { label: 'Rozliczenia', route: '/settlements' }
  ]
};

export function getUserNavByPermission(
  permission: UserPermission
): INavItem[] | undefined {
  return USER_NAV_ROLES[permission];
}
