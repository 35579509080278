import { Inject, Injectable } from '@angular/core';
import { ERROR_VALIDATION_MESSAGES } from '../const/tokens';
import { ErrorValidationMessageMap } from '../const/global-error-validation-messages';

@Injectable({
  providedIn: 'root'
})
export class ErrorValidationMessagesService {
  constructor(
    @Inject(ERROR_VALIDATION_MESSAGES)
    private validationMessages: ErrorValidationMessageMap
  ) {}

  getParametrizedMessage(validationMsg: string, validatorValue?: any): string {
    let message = validationMsg;
    if (validatorValue) {
      message = (message as string).replace(
        /\{(.*?)\}/,
        (substring, paramId) => {
          return validatorValue[paramId] || paramId;
        }
      );
    }
    return message;
  }
}
