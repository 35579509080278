import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  throwError
} from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, timeout } from 'rxjs/operators';
import { SnackBarService } from '../services/snack-bar.service';
import { cloneDeep } from 'lodash';
import { IApiError } from '../../shared/types/api';
import {
  includeApiErrorForResponse,
  isTimeoutError
} from '../utils/error-utils';
import { AuthenticationService } from '../services/authentication.service';
import { REQUEST_TIMEOUT } from '../const/request-timeout';
import { defualtApiError, timeoutApiError } from '../const/api-error-messages';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor, OnDestroy {
  private sub: Subscription | undefined;
  private errorSub = new BehaviorSubject<HttpErrorResponse[]>([]);
  private firstError$: Observable<HttpErrorResponse> = this.errorSub
    .asObservable()
    .pipe(
      // @ts-ignore
      map((errors) => (errors.length > 0 ? errors[0] : null)),
      filter<HttpErrorResponse>((err) => !!err)
    );

  constructor(
    private snackBarService: SnackBarService,
    private authService: AuthenticationService
  ) {
    this.asyncHandleFirstError();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      timeout(REQUEST_TIMEOUT),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && !err.url?.includes('refresh-token') && this.authService.hasValidRefreshToken) {
          return this.tryRefreshToken(next, req)
        }
        this.addError(err);
        this.handleSyncError(err);
        return throwError(err);
      })
    );
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private handleSyncError(err: HttpErrorResponse): void {
    switch (err.status) {
      case HttpStatusCode.Unauthorized:
        this.authService?.logout();
        break;
      default:
        break;
    }
  }

  private asyncHandleFirstError(): void {
    this.sub = this.firstError$
      .pipe(concatMap((err) => this.showErrorResponseSnackbar(err)))
      .subscribe(() => this.removeFirstError());
  }

  private showErrorResponseSnackbar(
    err: HttpErrorResponse
  ): Observable<unknown> {
    switch (err.status) {
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
        return of(null);
        break;
      default:
        return this.snackBarService
          .errorFromApi(this.createErrorFromApi(err))
          .afterDismissed();
    }
  }

  private addError(err: HttpErrorResponse): void {
    this.errorSub.next([...this.getCurrentErrors(), err]);
  }

  private removeFirstError(): void {
    const newErrors = this.getCurrentErrors();
    newErrors.shift();
    this.errorSub.next(newErrors);
  }

  private getCurrentErrors(): HttpErrorResponse[] {
    return cloneDeep(this.errorSub.getValue());
  }

  private createErrorFromApi(err: HttpErrorResponse): IApiError {
    let tmp;
    if (isTimeoutError(err)) {
      tmp = timeoutApiError;
    } else {
      tmp = includeApiErrorForResponse(err) ? err.error : defualtApiError;
    }
    return tmp;
  }

  private tryRefreshToken(next: HttpHandler, request: HttpRequest<any>) {
    return this.authService.interceptorRefreshToken().pipe(
      switchMap((res) =>
        next.handle(
          request.clone({
            setHeaders: {
              authorization: `Bearer ${res.access}`,
            },
          })
        )
      )
    );
  }
}
