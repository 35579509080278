import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './logo/logo.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderComponent } from './header/header.component';
import { BtnSpinnerComponent } from './btn-spinner/btn-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmButtonSheetComponent } from './confirm-button-sheet/confirm-button-sheet.component';
import { RadioControlComponent } from './radio-control/radio-control.component';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { FileComponent } from './file/file.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BottomSheetCloseBtnComponent } from './bottom-sheet-close-btn/bottom-sheet-close-btn.component';
import { RowLabelValueComponent } from './row-label-value/row-label-value.component';
import { ListCardComponent } from './list-card/list-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { AttachedFileComponent } from './attached-file/attached-file.component';
import { DirectivesModule } from '../directives/directives.module';
import { OrderComplaintComponent } from './order-complaint/order-complaint.component';
import { PipesModule } from '../pipes/pipes.module';
import { FetchFileComponent } from './fetch-file/fetch-file.component';
import { ContractStatusComponent } from './contract-status/contract-status.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ErrorMessagesModule } from '../modules/error-messages/error-messages.module';
import { CheckboxControlComponent } from './checkbox-control/checkbox-control.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HintComponent } from './hint/hint.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ConsentsAndDataProcessingComponent } from './consents_and_data_processing/consents-and-data-processing.component';

@NgModule({
  declarations: [
    LogoComponent,
    FileComponent,
    HeaderComponent,
    BtnSpinnerComponent,
    ConfirmButtonSheetComponent,
    RadioControlComponent,
    BottomSheetCloseBtnComponent,
    RowLabelValueComponent,
    ConsentsAndDataProcessingComponent,
    ListCardComponent,
    AttachedFileComponent,
    FetchFileComponent,
    OrderComplaintComponent,
    ContractStatusComponent,
    CheckboxControlComponent,
    HintComponent,
    PhoneInputComponent,
    TimePickerComponent,
    DatePickerComponent
  ],
  exports: [
    LogoComponent,
    BottomSheetCloseBtnComponent,
    FileComponent,
    HeaderComponent,
    BtnSpinnerComponent,
    ConfirmButtonSheetComponent,
    RadioControlComponent,
    CheckboxControlComponent,
    RowLabelValueComponent,
    ListCardComponent,
    AttachedFileComponent,
    FetchFileComponent,
    OrderComplaintComponent,
    ContractStatusComponent,
    ErrorMessagesModule,
    HintComponent,
    PhoneInputComponent,
    TimePickerComponent,
    ConsentsAndDataProcessingComponent,
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatIconModule,
    FlexLayoutModule,
    NgxMaterialTimepickerModule,
    DirectivesModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatCardModule,
    MatRippleModule,
    DirectivesModule,
    MatCheckboxModule,
    NgxMaskModule,
    MatDatepickerModule,
    PipesModule,
    MatInputModule,
    ErrorMessagesModule
  ]
})
export class ComponentsModule {}
