import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from '../services/users.service';
import { map, take, tap } from 'rxjs/operators';
import { IUser, UserPermission } from '../../shared/types/users';
import { AuthenticationService } from '../services/authentication.service';
import { ContractStatus } from '../../shared/types/contracts';

const FIRST_VISIT_URL = '/contracts/first-visit';
const CREATE_CONTRACT = '/contracts/create';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationAndContractStatusGuard
  implements CanActivateChild
{
  constructor(
    private userService: UsersService,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  checkIfNeedRedirectToContractPage(
    { contract_status, role }: IUser,
    route: RouterStateSnapshot
  ): boolean {
    return (
      role === UserPermission.guard &&
      (contract_status === 'N/A' || contract_status === ContractStatus.new) &&
      !route.url.includes('/contracts/')
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.userService.userLogged$.pipe(
      take(1),
      tap((user) =>
        !user.is_email_verified ? this.authService.logout(false) : null
      ),
      map((user) =>
        user.is_email_verified
          ? this.checkIfNeedRedirectToContractPage(user, state)
            ? this.router.parseUrl(
                user.contract_status === 'N/A' ||
                  user.contract_status === ContractStatus.new
                  ? FIRST_VISIT_URL
                  : CREATE_CONTRACT
              )
            : true
          : this.router.parseUrl('/error/403')
      )
    );
  }
}
