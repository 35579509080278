import {
  AfterViewInit,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[ghCardBtn]'
})
export class CardBtnDirective implements OnInit {
  @HostBinding('class.gh-card-btn')
  @Input()
  icon: string = 'arrow_forward';

  constructor(
    private factory: ComponentFactoryResolver,
    private vcRef: ViewContainerRef,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const factory = this.factory.resolveComponentFactory(MatIcon);
    const matIcon = this.vcRef.createComponent(factory);
    matIcon.instance.color = 'primary';
    matIcon.instance._elementRef.nativeElement.innerHTML = this.icon;
    const matIconEl = matIcon.injector.get(MatIcon)._elementRef.nativeElement;
    this.renderer.addClass(matIconEl, 'gh-card-btn__icon');
    this.renderer.appendChild(this.elementRef.nativeElement, matIconEl);
  }
}
