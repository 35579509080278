import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Injectable } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { Observable } from 'rxjs';

export const permissionGuardDataKey = 'permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(private permissions: PermissionService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    const permissions = route.data[permissionGuardDataKey];
    return permissions && this.permissions.hasPermission(permissions)
      ? true
      : this.router.parseUrl('/404');
  }
}
