import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger
} from '@angular/animations';

const CUBIC = 'cubic-bezier(.25, .8, .25, 1)';

export const enterFromTopTrigger = trigger('enterFromTop', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-100%)'
    }),
    animate(`.6s ${CUBIC}`)
  ])
]);
export const enterHeaderTrigger = trigger('enterHeader', [
  transition(':enter', [
    style({
      opacity: 0
    }),
    animate(`.3s ${CUBIC}`)
  ])
]);

export const enterLeaveFromTopTrigger = trigger('enterLeaveFromTop', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-100%)'
    }),
    animate(`.6s ${CUBIC}`)
  ]),
  transition(':leave', [
    animate(
      `.6s ${CUBIC}`,
      style({
        opacity: 0,
        transform: 'translateY(-100%)'
      })
    )
  ])
]);
export const enterLeaveFromBottomTrigger = trigger('enterLeaveFromBottom', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(100%)'
    }),
    animate(`.6s ${CUBIC}`)
  ]),
  transition(':leave', [
    animate(
      `.6s ${CUBIC}`,
      style({
        opacity: 0,
        transform: 'translateY(100%)'
      })
    )
  ])
]);
export const showSpinnerTrigger = trigger('showSpinner', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'scale(0)',
      width: 0
    }),
    animate(`.35s ${CUBIC}`)
  ]),
  transition(':leave', [
    animate(
      `.35s ${CUBIC}`,
      style({
        opacity: 0,
        transform: 'scale(0)',
        width: 0
      })
    )
  ])
]);
export const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    // each time the binding value changes
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'scale(.7)', height: '0px' }),
        stagger(100, [
          animate(
            `.35s ${CUBIC}`,
            style({ opacity: 1, transform: 'scale(1)', height: '*' })
          )
        ])
      ],
      { optional: true }
    )
  ])
]);
