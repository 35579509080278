import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional
} from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IApiError } from '../../types/api';
import { ErrorValidationMessagesService } from '../../../core/services/error-validation-messages.service';
import { ERROR_VALIDATION_MESSAGES } from '../../../core/const/tokens';
import {
  ErrorValidationMessage,
  ErrorValidationMessageMap
} from '../../../core/const/global-error-validation-messages';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ghErrorMessage]',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input()
  control: AbstractControl | null = null;
  @Input()
  apiError?: IApiError[];
  @Input()
  customErrorMessages?: Partial<ErrorValidationMessageMap>;
  @Input()
  showErrors = true;
  @Input()
  controlName?: string;
  private controlSubscribe: Subscription = new Subscription();

  constructor(
    @Inject(ERROR_VALIDATION_MESSAGES)
    private validationMessages: ErrorValidationMessageMap,
    private errorValidationMessageService: ErrorValidationMessagesService,
    private cd: ChangeDetectorRef,
    @Optional() private controlContainer: ControlContainer
  ) {}

  get showError(): boolean {
    return this.control ? this.control.dirty || this.control.touched : false;
  }

  private existsCustomValidationMessage(
    value: ErrorValidationMessage
  ): boolean {
    return !!this.customErrorMessages && !!this.customErrorMessages[value];
  }

  private getCustomValidationMessage(value: ErrorValidationMessage): string {
    // @ts-ignore
    return this.customErrorMessages[value];
  }

  get errorMessage(): string | null {
    if (this.control && this.showErrors) {
      for (const errorKey in this.control.errors) {
        if (
          this.control.errors[errorKey] &&
          this.showError &&
          errorKey !== 'apiError'
        ) {
          if (
            this.customErrorMessages &&
            this.isValidationMessage(errorKey) &&
            this.existsCustomValidationMessage(errorKey)
          ) {
            return this.errorValidationMessageService.getParametrizedMessage(
              this.getCustomValidationMessage(errorKey),
              this.control.errors[errorKey]
            );
          } else if (
            this.validationMessages &&
            !!this.isValidationMessage(errorKey)
          ) {
            return this.errorValidationMessageService.getParametrizedMessage(
              this.getValidationMessage(errorKey),
              this.control.errors[errorKey]
            );
          } else {
            return errorKey;
          }
        }
      }
    }
    if (this.apiError && this.apiError[0]?.message) {
      return this.apiError[0].message;
    }
    return null;
  }

  private getValidationMessage(value: ErrorValidationMessage): string {
    return this.validationMessages[value];
  }

  private isValidationMessage(value: string): value is ErrorValidationMessage {
    return !!Object.values(ErrorValidationMessage).find((x) => x === value);
  }

  ngOnDestroy(): void {
    if (this.controlSubscribe) {
      this.controlSubscribe.unsubscribe();
    }
  }

  addSubToControl() {
    if (this.control) {
      this.controlSubscribe.add(
        this.control.valueChanges.subscribe((data) => {
          if (this.apiError) {
            delete this.apiError;
          }
          this.cd.detectChanges();
        })
      );
      this.control.statusChanges.subscribe((value) => this.cd.detectChanges());
    }
  }

  private connectControl() {
    if (
      this.controlName &&
      this.controlContainer &&
      this.controlContainer.control instanceof FormGroup
    ) {
      this.control = (this.controlContainer.control as FormGroup).get(
        this.controlName
      );
    }
  }

  ngOnChanges(changes: any): void {
    if (changes.apiError && changes.apiError.currentValue && this.control) {
      this.control.setErrors({
        apiError: true
      });
      this.control.markAsDirty();
    }
  }

  ngAfterViewInit(): void {
    this.connectControl();
    this.addSubToControl();
  }
}
