import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'gh-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent implements OnInit {
  public isLogged$: Observable<boolean> = this.authService.loggedIn$.pipe(
    tap((isLogged) => (!isLogged ? this.closeSideNav.emit() : null))
  );
  @Output() toggleMenu = new EventEmitter();
  @Output() closeSideNav = new EventEmitter();
  @Input() isOpen!: boolean;

  constructor(private authService: AuthenticationService) {}

  ngOnInit(): void {}
}
