import { Component } from '@angular/core';
import { UsersService } from './core/services/users.service';

@Component({
  selector: 'gh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private userService: UsersService) {}

  title = 'guard-hire-web';
}
