import { IApiError } from '../../shared/types/api';

export const defualtApiError: IApiError = {
  message: 'Błąd API',
  code: '0'
};

export const timeoutApiError: IApiError = {
  message: 'Oczekiwanie na serwer trwało zbyt długo spróbuj ponownie',
  code: '0'
};
