import { IApiResponseEntityModel } from './api';
import { Voivodeship } from '../consts/voivodeship';
import { IShop } from 'src/app/shared/types/shop';

export enum ContractStatus {
  // @todo kmz delete N_A
  N_A = 'N/A',
  new = 'new',
  completed = 'completed',
  processing = 'processing',
  accepted = 'accepted',
  rejected = 'rejected',
  ended = 'ended'
}

export enum ShirtSize {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL'
}

export enum ShirtType {
  woman = 'woman',
  man = 'man'
}

export enum EmploymentType {
  'student' = 'student',
  'social_only' = 'social_only',
  'social_and_health' = 'social_and_health'
}

export interface IVerifiedContracts extends IContracts {
  bhp?: boolean;
  is_signed?: boolean;
  employment_type?: EmploymentType;
  verified_contract_date?: string;
  start_contract_date?: string;
}

export interface IRejectedContracts extends IVerifiedContracts {
  status: ContractStatus.rejected;
}

export interface IAcceptedContracts extends IVerifiedContracts {
  status: ContractStatus.accepted;
  employment_type: EmploymentType;
  verified_contract_date: string;
  start_contract_date: string;
}

export interface IContracts extends IApiResponseEntityModel, ICreateContracts {
  guard: string;
  comment: string;
  number_of_shops: number;
  completed_at: string;
  certificate_of_employment: string;
  non_penal_statement: boolean;
  email: string;
}

export interface ICreateContracts {
  flat_number: string;
  status: ContractStatus;
  first_name: string;
  last_name: string;
  phone_number: string;
  personal_id_number: string | null;
  passport_number: string | null;
  date_of_birth: string;
  place_of_birth: string;
  is_polish_citizen: boolean;
  street: string;
  street_number: string;
  pension: boolean;
  health_care_contribution: boolean;
  join_PPK: boolean;
  is_student: boolean;
  retirement: boolean;
  postal_code: string;
  post_office: string;
  city: string;
  borough: string;
  district: string;
  shop: IShop;
  voivodeship: Voivodeship;
  is_employed: boolean;
  is_payment_gte_minimal: boolean;
  tax_office: string;
  bank_account_number: string;
  nfz_unit: string;
  shirt_type: ShirtType;
  shirt_size: ShirtSize;
  data_processing_consent?: boolean;
  non_penal_statement?: boolean;
  bhp?: boolean;
}

export type ICreateContractsPartial = Partial<ICreateContracts>;

// @todo! move to /consts & /utils
export const isVerifiedContract = (
  c: IVerifiedContracts
): c is IRejectedContracts | IAcceptedContracts =>
  !!c &&
  !![ContractStatus.accepted, ContractStatus.rejected].find(
    (x) => x === c.status
  );

export const employmentTypeTranslateMap: Record<EmploymentType, string> = {
  [EmploymentType.social_and_health]: 'Pełny ZUS',
  [EmploymentType.social_only]: 'Inny zakład pracy',
  [EmploymentType.student]: 'Student'
};
