import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationalUnitsComponent } from './controls/organizational-units/organizational-units.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { TabSelectComponent } from './controls/tab-select/tab-select.component';
import { TabSelectLineComponent } from './controls/tab-select/tab-select-line.component';
import { FlexModule } from '@angular/flex-layout';
import { SearchInputComponent } from './controls/search-input/search-input.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OrganizationalUnitsControlComponent } from './controls/organizational-units-control/organizational-units-control.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ComponentsModule } from '../../components/components.module';
import { SortComponent } from './controls/sort/sort.component';
import { PasswordInputComponent } from './controls/password-input/password-input.component';

@NgModule({
  declarations: [
    OrganizationalUnitsComponent,
    OrganizationalUnitsControlComponent,
    TabSelectComponent,
    TabSelectLineComponent,
    SearchInputComponent,
    SortComponent,
    PasswordInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ComponentsModule,
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexModule,
    MatRippleModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    OrganizationalUnitsComponent,
    OrganizationalUnitsControlComponent,
    TabSelectComponent,
    SearchInputComponent,
    SortComponent,
    PasswordInputComponent
  ]
})
export class CustomFormControlsModule {}
