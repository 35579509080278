import { IApiResponseEntityModel } from './api';

export enum Consents {
  dataProcessingConsent = 'dataProcessingConsent',
  termsAndConditionsConsent = 'termsAndConditionsConsent',
  nonPenalStatement = 'nonPenalStatement'
}

export enum AttachmentType {
  certificate_of_employment = 'certificate_of_employment',
  other = 'other'
}

export interface IAttachment extends IApiResponseEntityModel {
  file: string;
  type: AttachmentType;
  contract: string;
  filename: string;
}

export interface ICreateAttachment {
  file: string;
  type: AttachmentType;
  filename: string;
}

// guard type
export const isIAttachment = (obj: unknown): obj is IAttachment => {
  return (
    obj != null &&
    typeof obj === 'object' &&
    !!(obj as IAttachment)?.file &&
    !!(obj as IAttachment)?.filename &&
    !!(obj as IAttachment)?.contract &&
    !!(obj as IAttachment)?.type &&
    !!(obj as IAttachment)?.id &&
    !!(obj as IAttachment)?.updated_at &&
    !!(obj as IAttachment)?.created_at
  );
};
