import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelfieCameraComponent } from './selfie-camera.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ComponentsModule } from '../../components/components.module';
import { SelfieCameraSelectStepComponent } from './components/selfie-camera-select-step/selfie-camera-select-step.component';
import { SelfieCameraCameraStepComponent } from './components/selfie-camera-camera-step/selfie-camera-camera-step.component';
import { SelfieCameraPreviewStepComponent } from './components/selfie-camera-preview-step/selfie-camera-preview-step.component';
import { SelfieCameraInstructionComponent } from './components/selfie-camera-instruction/selfie-camera-instruction.component';
import { MatButtonModule } from '@angular/material/button';
import { ButtonsModule } from '../buttons/buttons.module';
import { WebcamModule } from 'ngx-webcam';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [
    SelfieCameraComponent,
    SelfieCameraSelectStepComponent,
    SelfieCameraCameraStepComponent,
    SelfieCameraPreviewStepComponent,
    SelfieCameraInstructionComponent
  ],
  imports: [
    CommonModule,
    WebcamModule,
    MatDialogModule,
    ComponentsModule,
    MatButtonModule,
    ButtonsModule,
    MatFormFieldModule,
    MatIconModule,
    MatRippleModule
  ]
})
export class SelfieCameraModule {}
