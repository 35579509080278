import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import { ProfilePersonalDataComponent } from './components/profile-personal-data/profile-personal-data.component';
import { ProfileStatementsConsentComponent } from './components/profile-statements-consent/profile-statements-consent.component';
import { ProfileBlockWrapperComponent } from './components/profile-block-wrapper/profile-block-wrapper.component';
import { ProfileContactDetailsComponent } from './components/profile-contact-details/profile-contact-details.component';
import { ProfileAddressComponent } from './components/profile-address/profile-address.component';
import { ProfileOtherDataComponent } from './components/profile-other-data/profile-other-data.component';
import { ProfileDocumentsComponent } from './components/profile-documents/profile-documents.component';
import { PipesModule } from '../../pipes/pipes.module';
import { CustomFormControlsModule } from '../custom-form-controls/custom-form-controls.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DirectivesModule } from '../../directives/directives.module';
import { MatButtonModule } from '@angular/material/button';
import { ProfileEditContactComponent } from './components/profile-edit-contact/profile-edit-contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    ProfilePersonalDataComponent,
    ProfileAddressComponent,
    ProfileOtherDataComponent,
    ProfileDocumentsComponent,
    ProfileStatementsConsentComponent,
    ProfileBlockWrapperComponent,
    ProfileContactDetailsComponent,
    ProfileDetailsComponent,
    ProfileEditContactComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule,
    MatIconModule,
    FlexLayoutModule,
    CustomFormControlsModule,
    DirectivesModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    ProfilePersonalDataComponent,
    ProfileAddressComponent,
    ProfileOtherDataComponent,
    ProfileDocumentsComponent,
    ProfileStatementsConsentComponent,
    ProfileBlockWrapperComponent,
    ProfileContactDetailsComponent,
    ProfileDetailsComponent
  ]
})
export class ProfileDetailsModule {}
