import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar/snack-bar-ref';
import { TextOnlySnackBar } from '@angular/material/snack-bar/simple-snack-bar';
import { IApiError } from '../../shared/types/api';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    private readonly DEFAULT_DURATION = 4000;
    private readonly snackBarClass = {
        success: 'snackbar-success',
        error: 'snackbar-error'
    };

    constructor(private snackBar: MatSnackBar) {
    }

    success(msg: string, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
        return this.openSnackBar(msg, this.snackBarClass.success, duration);
    }

    error(msg: string, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
        return this.openSnackBar(msg, this.snackBarClass.error, duration);
    }

    errorFromApi(
        api: IApiError,
        duration = 6000
    ): MatSnackBarRef<TextOnlySnackBar> {
        return this.openSnackBar(api.message, this.snackBarClass.error, duration);
    }

    openSnackBar(
        msg: string,
        panelClass: string,
        duration?: number
    ): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(msg, 'Ok', {
            panelClass,
            duration: duration || this.DEFAULT_DURATION
        });
    }
}
