import { Component, Inject, OnInit } from '@angular/core';
import { enterLeaveFromBottomTrigger } from 'src/app/shared/animations';
import { LOCAL_STORAGE } from 'src/app/core/const/tokens';

@Component({
  selector: 'gh-cookie-info',
  templateUrl: './cookie-info.component.html',
  styleUrls: ['./cookie-info.component.scss'],
  animations: [enterLeaveFromBottomTrigger]
})
export class CookieInfoComponent implements OnInit {
  private readonly COOKIES_TOKEN = 'GH_COOKIES_INFO';
  private readonly COOKIES_TRUE = '1';
  public showCookie = false;

  constructor(@Inject(LOCAL_STORAGE) private storage: Storage) {}

  ngOnInit(): void {
    this.showCookie =
      this.storage.getItem(this.COOKIES_TOKEN) !== this.COOKIES_TRUE;
  }

  acceptCookies() {
    this.storage.setItem(this.COOKIES_TOKEN, this.COOKIES_TRUE);
    this.showCookie = false;
  }
}
