import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl } from '../../utils/utils';
import { IApiQueryBaseParams } from '../../shared/types/api';
import { map } from 'rxjs/operators';

export interface CollectionApiResponse<T> {
  items: T[];
  total: number;
}

export class ApiResource<F, C = F, P = Partial<IApiQueryBaseParams>> {
  protected apiUrl: string;

  constructor(resourceUrl: string, protected http: HttpClient) {
    this.apiUrl = apiUrl(resourceUrl);
  }

  public fetch(queryParams: P): Observable<CollectionApiResponse<F>> {
    const params = new HttpParams({
      fromObject: queryParams as {
        [key: string]: any;
      }
    });
    return this.http
      .get<F[]>(this.apiUrl, {
        params,
        observe: 'response'
      })
      .pipe(
        map((res) => {
          return {
            items: res.body as F[],
            total: Number(res.headers.get('total-count')) as number
          };
        })
      );
  }

  public fetchById(id: string): Observable<F> {
    return this.http.get<F>(`${this.apiUrl}/${id}`);
  }

  public create(data: C): Observable<F> {
    return this.http.post<F>(this.apiUrl, data);
  }

  public partialUpdate(id: string, data: Partial<C>): Observable<F> {
    return this.http.patch<F>(`${this.apiUrl}/${id}`, data);
  }

  public update(id: string, data: C): Observable<F> {
    return this.http.put<F>(`${this.apiUrl}/${id}`, data);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
