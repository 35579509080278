import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardBtnDirective } from './card-btn.directive';
import { MatButtonIconDirective } from './mat-button-icon.directive';

@NgModule({
  declarations: [CardBtnDirective, MatButtonIconDirective],
  imports: [CommonModule],
  exports: [CardBtnDirective, MatButtonIconDirective]
})
export class ButtonsModule {}
