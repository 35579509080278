import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gh-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  public errorCode$ = this.aRoute.paramMap.pipe(
    map((params) => params.get('errorCode'))
  );

  constructor(private aRoute: ActivatedRoute) {}

  ngOnInit(): void {}
}
