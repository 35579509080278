import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { apiUrl } from '../../utils/utils';

import {
  ICustomTokenObtainPair,
  ILoginUrl,
  ILoginWithCodeRequest,
  IResetPasswordConfirmPayload,
  IResetPasswordPayload,
  ITokenObtainPairResponse,
  LoginTypes
} from '../../shared/types/auth';
import { ApiUrls } from '../const/api-url';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public redirectUrl: string | null = null;
  private MAX_DELAY = 2147483647;
  private loginSubject: BehaviorSubject<boolean | undefined> =
    new BehaviorSubject<boolean | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private jwtHelper: JwtHelperService
  ) {
    this.setLoginState();
  }

  get hasValidRefreshToken(): boolean {
    const refreshToken = this.tokenService.refreshToken;

    return !!refreshToken && !this.jwtHelper.isTokenExpired(refreshToken);
  }

  get loggedIn$(): Observable<boolean> {
    return this.loginSubject.asObservable().pipe(
      distinctUntilChanged(),
      filter((value) => value !== undefined)
    ) as Observable<boolean>;
  }

  get token(): string | null {
    return this.tokenService.token;
  }

  private removeTokens(): void {
    this.tokenService.removeTokens();
  }

  //
  // public windowFocus(): void {
  //     const tokens = this.tokens;
  //     if (this.loggedIn() && tokens) {
  //         this.scheduleRefresh(tokens);
  //     } else {
  //         this.logout();
  //     }
  // }

  private hasValidToken(): boolean {
    const token = this.token;
    return token ? !this.jwtHelper.isTokenExpired(token) : false;
  }

  get tokenInfo(): any {
    if (this.token) {
      return this.jwtHelper.decodeToken(this.token);
    }
    return null;
  }

  private scheduleRefresh(tokens: string): void {
    const now: any = new Date().valueOf();
    const jwtExp = this.jwtHelper.decodeToken(tokens).exp;
    const exp = new Date(0);
    exp.setUTCSeconds(jwtExp);
    let delay = exp.valueOf() - now - 10000;
    if (delay > this.MAX_DELAY) {
      delay = this.MAX_DELAY;
    }
    setTimeout(() => {
      this.checkRefreshToken();
    }, delay);
  }

  public logout(withNavigateToLoginPage = true): void {
    this.removeTokens();
    this.loginSubject.next(false);
    if (withNavigateToLoginPage) {
      this.router.navigate(['login']);
    }
  }

  private checkRefreshToken(): void {
    const refreshToken = this.tokenService.refreshToken;
    if (
      refreshToken &&
      !this.jwtHelper.isTokenExpired(refreshToken) &&
      this.loginSubject.value === true
    )
      this.refreshToken(refreshToken).subscribe(
        (res: ITokenObtainPairResponse) => {
          this.tokenService.saveTokens(res);
          this.scheduleRefresh(res.access);
        },
        () => {
          this.logout();
        }
      );
  }

  public refreshToken(refresh: string): Observable<ITokenObtainPairResponse> {
    return this.http.post<ITokenObtainPairResponse>(apiUrl('auth/refresh'), {
      refresh
    });
  }

  public interceptorRefreshToken(): Observable<ITokenObtainPairResponse> {
    const refreshToken = this.tokenService.refreshToken as string;
    return this.refreshToken(refreshToken).pipe(
      tap(
        (x) => this.successLogin(x),
        catchError((err) => {
          this.logout();
          return throwError(err);
        })
      )
    );
  }

  public login(
    loginData: ICustomTokenObtainPair
  ): Observable<ITokenObtainPairResponse> {
    return this.http
      .post<ITokenObtainPairResponse>(
        apiUrl(ApiUrls.auth.guardLogin),
        loginData
      )
      .pipe(
        tap((res) => {
          this.successLogin(res);
          this.redirectAfterLogin();
        })
      );
  }

  public resetPassword(email: IResetPasswordPayload): Observable<void> {
    return this.http.post<void>(apiUrl(ApiUrls.auth.resetPassword), email);
  }

  public resetPasswordConfirm(
    payload: IResetPasswordConfirmPayload
  ): Observable<void> {
    return this.http.post<void>(
      apiUrl(ApiUrls.auth.resetPasswordConfirm),
      payload
    );
  }

  public loginWithCode(
    loginData: ILoginWithCodeRequest
  ): Observable<ITokenObtainPairResponse> {
    return this.http
      .post<ITokenObtainPairResponse>(
        apiUrl(ApiUrls.auth.shopOwner.login),
        loginData
      )
      .pipe(
        tap((res) => {
          this.successLogin(res);
          this.redirectAfterLogin();
        })
      );
  }

  redirectAfterLogin() {
    if (this.redirectUrl) {
      console.log(this.redirectUrl);
      this.router.navigateByUrl(this.redirectUrl, {
        state: {
          redirect: true
        }
      });
      this.redirectUrl = null;
    } else {
      this.router.navigate(['/']);
    }
  }

  public shopOwnerLoginUrl(): Observable<ILoginUrl> {
    return this.http.get<ILoginUrl>(apiUrl(ApiUrls.auth.shopOwner.loginUrl));
  }

  successLogin(tokens: ITokenObtainPairResponse) {
    this.tokenService.saveTokens(tokens);
    this.scheduleRefresh(tokens.access);
    this.loginSubject.next(true);
  }

  private setLoginState() {
    const isLoggedIn = this.hasValidToken();
    const refreshToken = this.tokenService.refreshToken;
    if (isLoggedIn) {
      this.scheduleRefresh(this.token as string);
      this.loginSubject.next(true);
    } else if (refreshToken && !this.jwtHelper.isTokenExpired(refreshToken)) {
      this.refreshToken(refreshToken).subscribe(
        (res) => {
          this.successLogin(res);
        },
        (error) => {
          this.tokenService.removeTokens();
          this.loginSubject.next(false);
        }
      );
    } else {
      this.loginSubject.next(false);
    }
  }
}
