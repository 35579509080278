<gh-nav-bar (toggleMenu)="snav.toggle()" (closeSideNav)="snav.close()" [isOpen]="snav.opened"></gh-nav-bar>
<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav #snav mode="over" [fixedInViewport]="true" fixedTopGap="50" position="end">
    <gh-sidenav-content (disableSidenav)="snav.close()"></gh-sidenav-content>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
<gh-cookie-info></gh-cookie-info>
