import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from 'src/app/core/services/users.service';
import { map } from 'rxjs/operators';
import { UserPermission } from 'src/app/shared/types/users';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsGuard implements CanActivate {
  constructor(private user: UsersService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.user.userLogged$.pipe(
      map((user) => {
        if (
          user.role === UserPermission.guard ||
          user.role === UserPermission.zabka_manager
        ) {
          return true;
        }
        if (
          !user.is_terms_of_use_accepted ||
          (user.role === UserPermission.shop_owner &&
            user.has_to_resign_from_withdrawal)
        ) {
          return this.router.parseUrl('terms-and-conditions');
        }
        return true;
      })
    );
  }
}
