import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { showSpinnerTrigger } from '../../animations';

@Component({
  selector: 'gh-btn-spinner',
  templateUrl: './btn-spinner.component.html',
  styleUrls: ['./btn-spinner.component.scss'],
  animations: [showSpinnerTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BtnSpinnerComponent implements OnInit {
  @Input() color: ThemePalette | 'white' = 'white';

  @HostBinding('@showSpinner') showSpinner = true;

  constructor() {}

  ngOnInit(): void {}
}
