import { HttpErrorResponse } from '@angular/common/http';
import { TimeoutError } from 'rxjs';

export const includeApiErrorForResponse = (res: HttpErrorResponse): boolean => {
  const tmp =
    !!res &&
    !!res.error &&
    [res.error.message, res.error.code].every(
      (value) => value != null && typeof value === 'string'
    );
  return tmp;
};

export const isTimeoutError = (res: any): boolean => {
  return res instanceof TimeoutError;
};

export const maxSizeFileErrorMessage = (name: string): string => {
  return `Dokument: ${name} jest za duży maksymalnie można dodać dokument 5 MB`;
};
