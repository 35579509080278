import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { UserPermission } from '../../shared/types/users';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private userPermission: UserPermission | null = null;
  private userPermissionSubject = new BehaviorSubject<UserPermission | null>(
    null
  );
  public permissions = UserPermission;

  constructor(private authService: AuthenticationService) {
    this.authService.loggedIn$.subscribe((isLogged) => {
      if (isLogged) {
        const tokenInfo = this.authService.tokenInfo;
        if (tokenInfo) {
          this.userPermission = tokenInfo.role;
        }
      } else {
        this.userPermission = null;
      }
      this.userPermissionSubject.next(this.userPermission);
    });
  }

  get userPermission$(): Observable<UserPermission | null> {
    return this.userPermissionSubject.asObservable();
  }

  hasPermission(permission: UserPermission | UserPermission[]): boolean {
    if (!permission || !this.userPermission) {
      return false;
    }
    if (Array.isArray(permission)) {
      return !!permission.find((perm) => this.permission === perm);
    } else {
      return this.permission === permission;
    }
  }

  get permission(): UserPermission | null {
    return this.userPermission;
  }
}
