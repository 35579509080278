
<ng-container [formGroup]="form">
  <ng-container>
    <ul>
      <li>
        <mat-checkbox formControlName="terms_of_use"
                      required
                      color="primary">Zapoznałem się i akceptuję  <a [href]="termsUrl" target="_blank" class="link">regulamin</a>.
        </mat-checkbox>
        <mat-error class="pl-l" ghErrorMessage
                  controlName="terms_of_use"
                   [apiError]="apiErrors?.terms_of_use"></mat-error>
      </li>
      <li class="mt-m">
        <mat-checkbox formControlName="data_processing_consent"
                      color="primary">{{ConsentsTranslate[Consents.dataProcessingConsent]}}
          <a [href]="applicationPrivacyPolicyUrl" class="link link_data">Więcej informacji o przetwarzaniu danych</a>
        </mat-checkbox>
        <mat-error class="pl-l" ghErrorMessage
                   controlName="data_processing_consent"
                   [apiError]="apiErrors?.data_processing_consent"></mat-error>
      </li>
    </ul>
  </ng-container>
</ng-container>
