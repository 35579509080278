import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ITokenObtainPairResponse } from '../../shared/types/auth';
import { LOCAL_STORAGE } from '../const/tokens';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private readonly TOKEN_NAME = environment.tokenName;
  private readonly REFRESH_TOKEN_NAME = environment.tokenName + '_r';
  private tempToken: string | null = null;

  constructor(@Inject(LOCAL_STORAGE) private storage: Storage) {
    this.tempToken = this.token;
  }

  saveTokens(tokens: ITokenObtainPairResponse) {
    this.token = tokens.access;
    this.refreshToken = tokens.refresh;
  }

  set token(token) {
    if (!!token) {
      this.storage.setItem(this.TOKEN_NAME, token);
    } else {
      this.removeTokens();
    }
    this.tempToken = token;
  }

  get token(): string | null {
    return this.tempToken || this.storage.getItem(this.TOKEN_NAME);
  }

  set refreshToken(token) {
    if (!!token) {
      this.storage.setItem(this.REFRESH_TOKEN_NAME, token);
    }
  }

  get refreshToken(): string | null {
    return this.storage.getItem(this.REFRESH_TOKEN_NAME);
  }

  public removeTokens(): void {
    this.removeToken();
    this.removeRefreshToken();
  }

  public removeToken(): void {
    this.storage.removeItem(this.TOKEN_NAME);
  }

  public removeRefreshToken(): void {
    this.storage.removeItem(this.REFRESH_TOKEN_NAME);
  }
}
