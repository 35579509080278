import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Sentry from '@sentry/angular';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { Router, RouterModule } from '@angular/router';
import { TokenService } from './services/token.service';
import { JWT_OPTIONS, JwtConfig, JwtModule } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ViewsModule } from './views/views.module';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SharedModule } from '../shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidenavContentComponent } from './components/sidenav-content/sidenav-content.component';
import { HttpHeadersInterceptor } from './interceptors/http-headers.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor.service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CookieInfoComponent } from './components/cookie-info/cookie-info.component';

export function jwtOptionsFactory(tokenService: TokenService): JwtConfig {
  return {
    allowedDomains: [environment.apiUrl.replace(/(^\w+:|^)\/\//, '')],
    tokenGetter: () => {
      return tokenService.token;
    }
  };
}

const maskConfig: Partial<IConfig> = {
  validation: true
};

@NgModule({
  declarations: [NavBarComponent, SidenavContentComponent, CookieInfoComponent],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'PLN' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpHeadersInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpErrorInterceptor
    }
  ],
  exports: [
    NavBarComponent,
    SidenavContentComponent,
    SharedModule,
    CookieInfoComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ViewsModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenService]
      }
    }),
    RouterModule,
    SharedModule,
    MatSidenavModule,
    NgxMaskModule.forRoot(maskConfig)
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
