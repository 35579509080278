import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SafeBase64SvgPipe } from './safe-svg.pipe';
import { PhonePipe } from './phone.pipe';
import { NullablePipe } from './nullable.pipe';
import { BooleanPipe } from './boolean.pipe';
import { OrderRatePipe } from './order-rate.pipe';
import { FullNamePipe } from './full-name.pipe';
import { OrderEndTimePipe } from './order-end-time.pipe';
import { OrderNumberOfHoursPipe } from './order-number-of-hours.pipe';
import { CustomDatePipePipe } from './gh-date.pipe';
import { StatusMapPipe } from './status-map.pipe';
import { MinMaxTimepickerPipe } from './min-max-timepicker.pipe';
import { ShopLocalizationPipe } from './shoplocalization.pipe';
import { BankNumberPipe } from './bank-number.pipe';

@NgModule({
  declarations: [
    SafeBase64SvgPipe,
    PhonePipe,
    NullablePipe,
    BooleanPipe,
    OrderRatePipe,
    FullNamePipe,
    OrderEndTimePipe,
    OrderNumberOfHoursPipe,
    CustomDatePipePipe,
    StatusMapPipe,
    MinMaxTimepickerPipe,
    ShopLocalizationPipe,
    BankNumberPipe
  ],
  exports: [
    SafeBase64SvgPipe,
    PhonePipe,
    NullablePipe,
    BooleanPipe,
    OrderRatePipe,
    FullNamePipe,
    OrderEndTimePipe,
    OrderNumberOfHoursPipe,
    CustomDatePipePipe,
    StatusMapPipe,
    MinMaxTimepickerPipe,
    ShopLocalizationPipe,
    BankNumberPipe
  ],
  providers: [DatePipe],
  imports: [CommonModule]
})
export class PipesModule {}
