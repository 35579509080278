import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser, IUserChangePasswordData } from '../../../shared/types/users';
import { apiUrl } from '../../../utils/utils';
import { ApiUrls } from '../../const/api-url';
import { IPagination } from '../../../shared/types/api';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(private httpClient: HttpClient) {}

  fetchUser(): Observable<IUser> {
    return this.httpClient.get<IUser>(apiUrl(ApiUrls.users.me));
  }

  changePassword(data: IUserChangePasswordData): Observable<null> {
    return this.httpClient.put<null>(
      apiUrl(ApiUrls.users.changePassword),
      data
    );
  }

  public partialUpdate(data: Partial<IUser>): Observable<IUser> {
    return this.httpClient.patch<IUser>(apiUrl(ApiUrls.users.me), data);
  }

  fetchUserGuards(pagination: IPagination): Observable<IUser[]> {
    const params = new HttpParams({
      fromObject: pagination as { [key: string]: any }
    });
    return this.httpClient.get<IUser[]>(apiUrl(ApiUrls.users.guards), {
      params
    });
  }
}
