<div *ngIf="showCookie" @enterLeaveFromBottom class="container">
  <div class="wrapper">


    <p>
      Aplikacja wykorzystuje pliki cookies m.in. w celu poprawienia jej dostępności, personalizacji, obsługi kont 
      użytkowników oraz aby zbierać dane, dotyczące ruchu na stronie. Każdy może sam decydować o tym, czy dopuszcza
      pliki cookies, ustawiając odpowiednio swoją przeglądarkę.

    </p>
    <button mat-raised-button color="primary" (click)="acceptCookies()">Ok</button>
  </div>
</div>
