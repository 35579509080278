import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { Observable } from 'rxjs';
import { INavItem } from '../../../shared/types/utils';

@Component({
  selector: 'gh-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss']
})
export class HomeViewComponent implements OnInit {
  public navItems$?: Observable<INavItem[] | undefined> =
    this.userService.userNavigation$;

  constructor(private userService: UsersService) {}

  ngOnInit(): void {}
}
