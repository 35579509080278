<ng-container *ngIf="shopAssociation$|async as shopAssociation">
  <h1>Zaproś do współpracy!</h1>
  <p>Udostępnij poniższy link osobie, którą chcesz zaprosić do współpracy </p>
  <div class="link-box">
    <a [href]="shopAssociation.register_url" class="link">{{shopAssociation.register_url}}</a><br>
    <a  class="copy-link" [cdkCopyToClipboard]="shopAssociation.register_url" (cdkCopyToClipboardCopied)="onCopyToClipboardCopied($event)">Kopiuj do schowka</a>
  </div>

  <p>lub</p>
  <div>
    <p>Poproś ją o zeskanowanie Twojego kodu QR</p>
    <img [src]="shopAssociation.qr_code|safeBase64Svg"/>
  </div>
</ng-container>
