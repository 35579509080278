<section *ngIf="user$|async as user" class="py-m">

  <div *ngIf="!user.is_terms_of_use_accepted" class="mb-xxl">
    <h2>Regulamin</h2>
    <gh-consents-and-data [form]="termsForm" [apiErrors]="apiError"></gh-consents-and-data>
  </div>

  <div class="mb-xxl" *ngIf="user.has_to_resign_from_withdrawal && user.role === permission.shop_owner">
    <mat-checkbox [formControl]="rightToWithdraw"

                  color="primary">
      <span>Zgłaszam żądanie rozpoczęcia świadczenia usług realizowanych przez agencję ochrony przed upływem terminu do odstąpienia od zawartej Umowy o świadczenie usługi ochrony mienia.</span>
    </mat-checkbox>
    <div class="mt-m">
      <mat-hint class="">
        Jeśli zaznaczysz pole „Zgłaszam żądanie”, od razu będziesz miał możliwości składania Zamówień na usługi
        realizowane przez agencję ochrony w ramach zawartej Umowy o świadczenie usługi ochrony mienia. <br>W przeciwnym
        wypadku zamówienia na usługi realizowane przez agencję ochrony będziesz mógł składać dopiero po upływie 14 dni
        od dnia zawarcia Umowy o świadczenie usługi ochrony mienia.
      </mat-hint>
    </div>

    <div>
      <mat-error ghErrorMessage [control]="rightToWithdraw"
                 [apiError]="apiError?.has_resigned_from_withdrawal"></mat-error>
    </div>
  </div>
  <mat-error ghErrorMessage [apiError]="apiError?.non_field_errors"></mat-error>
  <div class="text-left">
    <button mat-flat-button color="primary" [disabled]="pending || !termsForm.valid" class="px-xxl"
            (click)="onSubmit()">
      <gh-btn-spinner *ngIf="pending"></gh-btn-spinner>
      Zapisz
    </button>
  </div>

</section>
