export const ApiUrls = {
  auth: {
    guardLogin: 'auth/guard/login',
    resetPassword: 'auth/password-reset',
    resetPasswordConfirm: 'auth/password-reset-confirm',
    refresh: 'auth/refresh',
    shopOwnerLogin: 'auth/shop-owner/login',
    shopOwner: {
      loginUrl: 'auth/shop-owner/login-url',
      login: 'auth/shop-owner/login-with-code'
    }
  },
  orders: 'orders',
  contracts: 'contracts',
  shop: {
    main: 'shops',
    association: 'association'
  },
  complaints: 'complaints',
  terms: 'content/terms-of-service',
  settlements: 'settlements',
  applicationPrivacyPolicy: 'content/application-privacy-policy',
  users: {
    me: 'users/me',
    emailConfirm: 'users/email-confirm',
    register: 'users/register',
    guards: 'users/guards',
    changePassword: 'users/password-change'
  }
};
