<div>
  <div *ngIf="navItems | async as navItems" fxLayout="column" fxLayoutAlign="space-between start">
    <a mat-button *ngFor="let navItem of navItems" [routerLink]="navItem.route"
       (click)="disableSidenav.emit()">{{navItem.label}}</a>
  </div>
</div>
<div>

  <h5 *ngIf="user$ | async as user" class="user-info">{{user.email}} <p *ngIf="showVersion">
    <strong>v. {{version}}</strong></p></h5>
  <div class="divider"></div>
  <button mat-button color="warn" (click)="logout()">
    <mat-icon>logout</mat-icon>
    Wyloguj
  </button>
</div>



