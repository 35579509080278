import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeViewComponent } from './home-view/home-view.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ErrorPageComponent } from './error-page/error-page.component';
import { InvitationViewComponent } from './invitation-view/invitation-view.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

@NgModule({
  declarations: [
    HomeViewComponent,
    ErrorPageComponent,
    InvitationViewComponent,
    TermsAndConditionsComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule, ClipboardModule]
})
export class ViewsModule {}
