import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Consents } from 'src/app/shared/types/attachment';
import { IApiErrorValidationResponse } from 'src/app/shared/types/api';
import { environment } from 'src/environments/environment';
import { consentsTranslateMap } from 'src/app/core/const/consents';
import { ApiUrls } from 'src/app/core/const/api-url';
import { IRegistration } from 'src/app/modules/authentication/types/registration';

@Component({
  selector: 'gh-consents-and-data',
  templateUrl: './consents-and-data-processing.component.html',
  styleUrls: ['./consents-and-data-processing.component.scss']
})
export class ConsentsAndDataProcessingComponent {
  @Input()
  public form!: FormGroup;
  @Input()
  public name!: string;

  @Input()
  public apiErrors?: IApiErrorValidationResponse<IRegistration>;
  public termsUrl = `${environment.apiUrl}/${ApiUrls.terms}`;
  public applicationPrivacyPolicyUrl = `${environment.apiUrl}/${ApiUrls.applicationPrivacyPolicy}`;
  public readonly Consents = Consents;
  public readonly ConsentsTranslate = consentsTranslateMap;

  constructor() {}
}
