export type ErrorValidationMessageMap = Record<ErrorValidationMessage, string>;

export enum ErrorValidationMessage {
  required = 'required',
  min = 'min',
  max = 'max',
  digits = 'digits',
  pesel = 'pesel',
  email = 'email',
  passport = 'passport',
  mismatch = 'mismatch',
  maxlength = 'maxlength',
  minlength = 'minlength',
  notSamePassword = 'notSamePassword',
  backAccountNumber = 'backAccountNumber',
  pattern = 'pattern',
  selectOption = 'selectOption',
  matDatepickerMax = 'matDatepickerMax',
  matDatepickerMin = 'matDatepickerMin',
  matDatepickerParse = 'matDatepickerParse',
  mask = 'mask',
  noOnlyDigit = 'noOnlyDigit',
  dateNotValidWithPesel = 'dateNotValidWithPesel',
}

export const globalErrorValidationMessages: ErrorValidationMessageMap = {
  required: 'To pole jest wymagane',
  min: 'Minimalna wartość to {min}',
  max: 'Maksymalna wartość to {max}',
  digits: 'Tylko cyfry',
  pesel: 'To nie jest poprawny numer pesel',
  email: 'To nie jest poprawny adres email',
  passport: 'To nie jest poprawny numer paszportu',
  mismatch: 'mismatch tekst',
  maxlength: 'Maksymalna liczba znaków: {requiredLength}',
  minlength: 'Minimalna liczba znaków: {requiredLength}',
  notSamePassword: 'Hasła nie są identyczne',
  backAccountNumber: 'Niepoprawny numer rachunku bankowego',
  pattern: 'Nieprawidłowa wartość pola',
  selectOption: 'Wybierz wartość z dostępnych opcji',
  matDatepickerMax: 'Użytkownik musi mieć skończone 18 lat',
  matDatepickerMin: 'Nieprawidłowa data urodzenia',
  matDatepickerParse: 'Niepoprawny format daty',
  mask: 'Niepoprawny format',
  noOnlyDigit: 'W polu nie mogą znajdować się wyłącznie cyfry',
  dateNotValidWithPesel: 'Data urodzenia nie jest zgodna z numerem PESEL',
};
