import { IApiResponseEntityModel } from './api';
import { ContractStatus } from './contracts';

export enum UserPermission {
  shop_owner = 'shop_owner',
  guard = 'guard',
  manager = 'manager',
  admin = 'admin',
  zabka_manager = 'zabka_manager'
}

// @todo delete N_A option from ContractStatus
export type NullableContractStatus = ContractStatus | 'N/A';

export interface IUser extends IApiResponseEntityModel {
  email: string;
  first_name: string;
  contact_email: string;
  last_name: string;
  min_hourly_rate: string;
  has_to_resign_from_withdrawal: boolean;
  has_resigned_from_withdrawal: boolean;
  role: UserPermission;
  shop_association: string;
  terms_of_use_accepted_at: Date | null;
  phone_number: string;
  security_agency_privacy_policy_url: string;
  is_email_verified: boolean;
  is_terms_of_use_accepted: boolean;
  is_data_processing_consent_accepted: boolean;
  contract_status: NullableContractStatus;
}

export interface IUserChangePasswordData {
  old_password: string;
  new_password: string;
}
