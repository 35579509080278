// @todo! move to consts/consents
import { Consents } from 'src/app/shared/types/attachment';

export const consentsTranslateMap: Record<Consents, string> = {
  [Consents.termsAndConditionsConsent]: `Zapoznałem się i akceptuję`,
  [Consents.dataProcessingConsent]:
    'Oświadczam, iż zostałem poinformowany, że administratorem danych osobowych podanych w ramach Platformy i w celu korzystania z jej funkcjonalności jest Future Mind Sp. z o. o., ul. Puławska 182, 02-670 Warszawa.',
  [Consents.nonPenalStatement]:
    'Świadomy/a odpowiedzialności karnej za składanie fałszywych zeznań oświadczam, iż nie byłem/byłam skazany/a prawomocnym wyrokiem za przestępstwo umyślne'
};
