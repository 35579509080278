import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResource } from '../../class/api-resource.class';
import { IShop, IShopAssociation } from '../../../shared/types/shop';
import { ApiUrls } from '../../const/api-url';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UsersService } from '../users.service';
import { filter, map, switchMap, tap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShopsService extends ApiResource<IShop> {
  private shopSubject?: BehaviorSubject<IShop[]>;

  constructor(http: HttpClient, private userService: UsersService) {
    super(ApiUrls.shop.main, http);
  }

  get shops$(): Observable<IShop[]> {
    if (this.shopSubject) {
      return this.shopSubject.asObservable().pipe(take(1));
    }
    return this.fetch({
      page: 1,
      page_size: 9999
    }).pipe(
      map((collection) => collection.items),
      tap((shops) => (this.shopSubject = new BehaviorSubject<IShop[]>(shops)))
    );
  }

  shopAssociation(id: string): Observable<IShopAssociation> {
    return this.http.get<IShopAssociation>(
      `${this.apiUrl}/${ApiUrls.shop.association}/${id}`
    );
  }

  shopAssociationByLoggedUser(): Observable<IShopAssociation> {
    return this.userService.userLogged$.pipe(
      map(({ shop_association }) => shop_association),
      switchMap((shop_association) => this.shopAssociation(shop_association))
    );
  }
}
