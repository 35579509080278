import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './has-permission.directive';
import { DownloadDirective } from './download.directive';

@NgModule({
  declarations: [HasPermissionDirective, DownloadDirective],
  exports: [HasPermissionDirective, DownloadDirective],
  imports: [CommonModule]
})
export class DirectivesModule {}
