import { environment } from '../../environments/environment';

export function apiUrl(path: string): string {
  return `${environment.apiUrl}/${path}`;
}

export function getDateFromPersonalIdNumber(pesel: string): Date | null {
  if (typeof pesel !== 'string' || pesel.length !== 11) {
    return null;
  }

  let year = parseInt(pesel.substring(0, 2), 10);
  let month = parseInt(pesel.substring(2, 4), 10) - 1;
  const day = parseInt(pesel.substring(4, 6), 10);
  const thirdChar = parseInt(pesel.substr(2, 1));

  switch (thirdChar) {
    case 2:
    case 3:
      month -= 20;
      year += 2000;
      break;

    case 4:
    case 5:
      month -= 40;
      year += 2100;
      break;

    case 6:
    case 7:
      month -= 60;
      year += 2200;
      break;

    case 8:
    case 9:
      month -= 80;
      year += 1800;
      break;

    default:
      year += 1900;
      break;
  }

  return new Date(year, month, day);
}
