import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription
} from 'rxjs';
import { IUser } from '../../shared/types/users';
import { AuthenticationService } from './authentication.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { PermissionService } from './permission.service';
import { INavItem } from '../../shared/types/utils';
import { getUserNavByPermission } from '../const/user-nav-links';
import { UserApiService } from './http-resource/user-api.service';

const defualtUserState: IUserState = {
  user: null,
  pending: false
};

interface IUserState {
  user: IUser | null;
  pending: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService implements OnDestroy {
  private userSubject = new BehaviorSubject<IUserState>(defualtUserState);
  private refresher = new BehaviorSubject<void>(undefined);
  private subscription!: Subscription;

  constructor(
    private authService: AuthenticationService,
    private userApi: UserApiService,
    private permissionService: PermissionService
  ) {
    this.handleUserState();
  }

  get user$(): Observable<IUser | null> {
    return this.userSubject.asObservable().pipe(map((state) => state.user));
  }

  getUser(): IUser | null {
    const store = this.userSubject.getValue();
    return store.user;
  }

  get userLogged$(): Observable<IUser> {
    return this.user$.pipe(filter((user): user is IUser => user !== null));
  }

  get userNavigation$(): Observable<INavItem[]> {
    return this.permissionService.userPermission$.pipe(
      map((permission) =>
        permission ? getUserNavByPermission(permission) || [] : []
      )
    );
  }

  loadUser(): void {
    this.refresher.next();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadingOn(): void {
    const user = this.userSubject.getValue().user;
    this.userSubject.next({
      user: !!user ? { ...user } : null,
      pending: true
    });
  }

  private handleUserState() {
    this.subscription = this.triggerFetchUser()
      .pipe(
        tap(() => this.loadingOn()),
        switchMap(([loginState, _]) =>
          loginState ? this.userApi.fetchUser() : of(null)
        ),
        tap(this.setSentryUser)
      )
      .subscribe((value) => {
        this.userSubject.next({ user: value, pending: false });
      });
  }

  private triggerFetchUser(): Observable<[boolean, void]> {
    return combineLatest([
      this.authService.loggedIn$,
      this.refresher.asObservable()
    ]);
  }

  private setSentryUser(user: IUser | null): void {
    Sentry.setUser({
      email: user?.email,
      id: user?.id
    });
  }

  acceptTerms(value: boolean): Observable<IUser> {
    return this.userApi
      .partialUpdate({
        is_terms_of_use_accepted: true,
        is_data_processing_consent_accepted: true,
        ...(value ? { has_resigned_from_withdrawal: value } : {})
      })
      .pipe(tap((user) => this.userSubject.next({ user, pending: false })));
  }
}
