import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorMessageComponent } from './error-message.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [ErrorMessageComponent],
  exports: [ErrorMessageComponent],
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule]
})
export class ErrorMessagesModule {}
