import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { HomeViewComponent } from './core/views/home-view/home-view.component';
import { EmailVerificationAndContractStatusGuard } from './core/guards/email-verification-and-conctract-status-guard.service';
import { ErrorPageComponent } from './core/views/error-page/error-page.component';
import { InvitationViewComponent } from './core/views/invitation-view/invitation-view.component';
import { PermissionsGuard } from './core/guards/permissions.guard';
import { UserPermission } from './shared/types/users';
import { AppReadySyncGuard } from './app-ready-sync.guard';
import { TermsAndConditionsGuard } from 'src/app/core/guards/terms-and-conditions.guard';
import { TermsAndConditionsComponent } from 'src/app/core/views/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  {
    path: 'login',
    redirectTo: 'auth/login'
  },
  {
    path: '',
    canActivate: [AuthGuardService, AppReadySyncGuard],
    canActivateChild: [EmailVerificationAndContractStatusGuard],
    children: [
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent
      },
      {
        path: '',
        canActivate: [TermsAndConditionsGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: HomeViewComponent
          },
          {
            path: 'profile',
            loadChildren: () =>
              import('./modules/profile/profile.module').then(
                (m) => m.ProfileModule
              )
          },
          {
            path: 'orders',
            loadChildren: () =>
              import('./modules/orders/orders.module').then(
                (m) => m.OrdersModule
              )
          },
          {
            path: 'settlements',
            loadChildren: () =>
              import('./modules/settlements/settlements.module').then(
                (m) => m.SettlementsModule
              )
          },
          {
            path: 'contracts',
            loadChildren: () =>
              import('./modules/contracts/contracts.module').then(
                (m) => m.ContractsModule
              )
          },
          {
            path: 'complaints',
            loadChildren: () =>
              import('./modules/complaints/complaints.module').then(
                (m) => m.ComplaintsModule
              )
          },
          {
            path: 'invitation',
            component: InvitationViewComponent,
            canActivate: [PermissionsGuard],
            data: {
              permissions: UserPermission.shop_owner
            }
          }
        ]
      }
    ]
  },
  {
    path: 'error/:errorCode',
    component: ErrorPageComponent
  },
  {
    path: '**',
    redirectTo: '/error/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
