import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { consentsTranslateMap } from 'src/app/core/const/consents';
import { ApiUrls } from 'src/app/core/const/api-url';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { UsersService } from 'src/app/core/services/users.service';
import { tap } from 'rxjs/operators';
import { IUser, UserPermission } from 'src/app/shared/types/users';
import { IApiErrorValidationResponse } from 'src/app/shared/types/api';
import { Router } from '@angular/router';
import { IRegistration } from 'src/app/modules/authentication/types/registration';

@Component({
  selector: 'gh-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  public pending = false;
  public permission = UserPermission;
  public apiError?: IApiErrorValidationResponse<IUser & IRegistration>;
  public termsForm = this.fb.group({
    terms_of_use: [false, Validators.requiredTrue],
    data_processing_consent: [false, Validators.requiredTrue]
  });
  public rightToWithdraw = new FormControl(false, Validators.required);
  private user!: IUser;
  public user$ = this.userService.userLogged$.pipe(
    tap((x) =>
      this.termsForm.patchValue({
        terms_of_use: x.is_terms_of_use_accepted,
        data_processing_consent: x.is_data_processing_consent_accepted
      })
    ),
    tap((x) => (this.user = x))
  );

  constructor(
    private userService: UsersService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.pending || !this.termsForm.valid) {
      this.termsForm.markAsTouched();
      return;
    }
    this.pending = true;
    this.userService.acceptTerms(this.rightToWithdraw.value).subscribe(
      (value) => {
        this.pending = false;
        this.router.navigate(['/']);
      },
      (e) => {
        this.apiError = e.error || e;
        this.pending = false;
      }
    );
  }
}
