import { Component, OnInit } from '@angular/core';
import { ShopsService } from '../../services/http-resource/shops.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'gh-invitation-view',
  templateUrl: './invitation-view.component.html',
  styleUrls: ['./invitation-view.component.scss']
})
export class InvitationViewComponent implements OnInit {
  public shopAssociation$ = this.shopService.shopAssociationByLoggedUser();

  constructor(
    private shopService: ShopsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  onCopyToClipboardCopied($event: boolean) {
    if ($event) {
      this.snackBar.open('Skopiowano link!', 'Ok', {
        panelClass: 'snackbar-success',
        duration: 3000
      });
    }
  }
}
