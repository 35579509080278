import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from './buttons/buttons.module';

@NgModule({
  declarations: [],
  exports: [ButtonsModule],
  imports: [CommonModule]
})
export class ModulesModule {}
