import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { INavItem } from '../../../shared/types/utils';
import { UsersService } from '../../services/users.service';
import { AuthenticationService } from '../../services/authentication.service';
import { IUser } from '../../../shared/types/users';
import { environment } from '../../../../environments/environment';
import packageM from '../../../../../package.json';

const version = packageM.version;

@Component({
  selector: 'gh-sidenav-content',
  templateUrl: './sidenav-content.component.html',
  styleUrls: ['./sidenav-content.component.scss']
})
export class SidenavContentComponent implements OnInit {
  @Output() disableSidenav = new EventEmitter();
  public showVersion = !environment.production;
  public version = version;
  public navItems?: Observable<INavItem[] | undefined> =
    this.userService.userNavigation$;
  public user$?: Observable<IUser | null> = this.userService.user$;

  constructor(
    private userService: UsersService,
    private authService: AuthenticationService
  ) {}

  logout() {
    this.authService.logout();
  }

  ngOnInit(): void {}
}
